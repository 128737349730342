@import "~@/assets/scss/_import.scss";













































.section[data-section="1"] {
  ::v-deep .fp-tableCell {
    display: flex;
    flex-direction: column;
  }

  .container {
    padding: {
      top: 55px;
      right: 38px;
      left: 45px;
    };

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
      padding: {
        top: 47px;
        bottom: 26px;
        left: 48px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
      padding: {
        top: 88px;
        right: 66px;
        left: 70px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
      padding: {
        top: 112px;
        bottom: 76px;
        left: 66px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
      padding: {
        top: 128px;
        right: 122px;
        left: 122px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
      padding-left: 76px;
      margin-top: auto;
      margin-bottom: auto;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
      max-width: map-get($grid-breakpoint, 'xl');
      margin: auto;
    }

    h1.title {
      color: #40A0D7;
      text-shadow: 0 0 32px rgba(82, 194, 255, 0.5);
    }

    p.description {
      @extend %body-font;
      color: rgba(#FFFFFF, 0.9);
    }

    .features {
      display: flex;
    }

    .feature-item {
      width: 100%;
      max-width: 50%;
      flex-basis: 50%;
    }

    .feature-item .icon {
      color: #5AACE8;
    }

    .feature-item .icon svg {
      width: 24px;
      height: 24px;

      @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
        width: 28px;
        height: 28px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
        width: 28px;
        height: 28px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
        width: 32px;
        height: 32px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
        width: 32px;
        height: 32px;
      }
    }

    .feature-item .title {
      @extend %h5;
      color: rgba(#FFFFFF, 0.9);
    }

    .order-delivery {
      width: 100%;
      max-width: 304px;
    }

    .order-delivery button {
      width: 100%;
      height: 40px;
      line-height: 40px;
      background: transparent;
      border: 2px solid #F5D21A;
      @extend %navigation;
      color: rgba(#FFFFFF, 0.8);
      border-radius: 1px;
      transition: color, box-shadow 100ms ease-in-out;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 16px #FFD702, 0 0 24px #FAFF02;
        color: #FFFFFF;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
        height: 48px;
        line-height: 48px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
        height: 48px;
        line-height: 48px;
      }
    }
  }
}
