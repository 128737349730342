@import "~@/assets/scss/_import.scss";












































































































































































.section {
  overflow: hidden;

  .fp-tableCell {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
      //justify-content: center;
    }
  }

  .container {
    padding: {
      top: 55px;
      right: 38px;
      left: 45px;
    };

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
      padding: {
        top: 47px;
        bottom: 26px;
        left: 48px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
      padding: {
        top: 88px;
        right: 66px;
        left: 70px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
      padding: {
        top: 112px;
        bottom: 76px;
        left: 66px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
      padding: {
        top: 128px;
        right: 122px;
        left: 122px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
      padding: {
        top: 144px;
        bottom: 88px;
        left: 76px;
      };
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
      //padding: {
      //  top: 148px;
      //  bottom: 133px;
      //};

      max-width: map-get($grid-breakpoint, 'xl');
      margin: auto;
    }

    h1.title {
      color: #40A0D7;
      text-shadow: 0 0 32px rgba(82, 194, 255, 0.5);
    }

    p.description {
      @extend %body-font;
      color: rgba(#FFFFFF, 0.9);
    }
  }
}
