@import "~@/assets/scss/_import.scss";



















































































































.third-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 4;

  @media screen and (max-width: 479px) {
    flex-direction: column-reverse;
  }

  .text {
    width: 30%;
    background-image: url('~@/assets/images/zavod_1_blur.jpg');
    position: relative;
    z-index: 5;

    @media screen and (max-width: 479px) {
      height: 50%;
      width: 100%;
    }

    .content {
      @include absolute-fill;
      background-color: rgba(#000000, 0.1);
      display: flex;
      flex-direction: column;
      padding: 18.41px 19px 20px 23px;

      @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
        padding: 19px 20px 18px 19px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
        padding: 43.87px 42px 38px 44px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
        padding: 29px 27px 32px 30px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
        padding: 87px 73px 63px 67px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
        padding: 38.94px 35px 41.93px 39.53px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
        padding: 44px 47.27px 47px 53.53px;
      }
    }

    h1 {
      color: #FFFFFF;
    }

    .description {
      @extend %body-font;
    }

    .back {
      display: flex;
      align-items: center;
      @extend %navigation;
      cursor: pointer;
      color: rgba(#FFF, 0.8);
      transition: 100ms ease-in-out;
      position: relative;
      text-decoration: none;

      .inactive,
      .active {
        transition: inherit;
      }

      .inactive {
        position: absolute;
      }

      .active {
        opacity: 0;
      }

      &:hover {
        .active {
          opacity: 1;
        }

        .inactive {
          opacity: 0;
        }
      }
    }

    .feature {
      .number {
        @extend %h2;
        color: #60B3E0;
      }

      .number span {
        @extend %h4;
        color: rgba(#FFF, 0.5);
      }

      .what {
        @extend %h4;
        color: rgba(#FFF, 0.9);
      }
    }

    .quotes {
      position: relative;
      @extend %navigation;
      display: flex;
      align-items: center;
      transition: 100ms ease-in-out;
      cursor: pointer;

      .inactive,
      .active {
        transition: inherit;
      }

      .inactive {
        position: absolute;
      }

      .active {
        opacity: 0;
      }

      &:hover {
        .active {
          opacity: 1;
        }

        .inactive {
          opacity: 0;
        }
      }
    }
  }

  .img-forward {
    position: relative;
    width: 70%;
    height: 100%;

    @media screen and (max-width: 479px) {
      width: 100%;
      height: 50%;
    }
  }

  .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    @media screen and (max-width: 479px) {
      top: -30%;
    }

    > * {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: 100vmax 100vh;

      @media screen and (max-width: 768px) {
        bottom: -50vh;
      }
    }

    .backdrop {
      background-image: url('~@/assets/images/zavod.jpg');
    }

    .train {
      background-image: url('~@/assets/images/train.png');
      animation: train 50s ease-in-out infinite;

      @media screen and (max-width: 479px) {
        animation: mobile-train 25s ease-in-out infinite;
        background-position: 24%;
      }
    }

    .smoke {
      background-image: url('~@/assets/images/smoke-min.gif');
      background-repeat: no-repeat;
      mix-blend-mode: screen;
      background-size: 10%;
      transform: translate(4px, -10px);
    }

    .smoke.smoke-1 {
      top: 12%;
      left: 47.5%;
    }

    .smoke.smoke-2 {
      top: 15%;
      left: 57.5%;
    }

    .cloud.cloud-1 {
      background-image: url('~@/assets/images/cloud1.png');
      background-repeat: no-repeat;
      bottom: -150px;
      animation: cloud 60s linear infinite reverse;
    }

    .cloud.cloud-2 {
      background-image: url('~@/assets/images/cloud2.png');
      background-repeat: no-repeat;
      animation: cloud2 25s linear infinite reverse;
    }
  }
}

@keyframes bg {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

@keyframes train {
  0% {
    transform: translate(-35%, 44%);
  }

  25% {
    transform: translate(0, 0);
  }

  45% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(56%, -70%);
  }
}

@keyframes mobile-train {
  0% {
    transform: translate(-47%, 22%);
  }

  25% {
    transform: translate(-9%, 5%);
  }

  45% {
    transform: translate(-9%, 5%);
  }

  100% {
    transform: translate(29%, -12%);
  }
}

@keyframes cloud {
  0% {
    top: -100vh;
    left: -200vw;
  }

  100% {
    top: -150px;
    left: 100vw;
  }
}

@keyframes cloud2 {
  0% {
    bottom: 100vh;
    left: -100vw;
  }

  100% {
    bottom: -150px;
    left: 100vw;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
