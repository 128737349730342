@import "~@/assets/scss/_import.scss";





























































.section[data-section="6"] {
  .container {
    h4.subtitle {
      color: rgba(#FFFFFF, 0.9);
    }

    .features {
      display: flex;
    }

    .feature-item {
      width: 100%;
      max-width: (100% / 3);
      flex-basis: (100% / 3);
    }

    .feature-item .icon {
      color: #5AACE8;
    }

    .feature-item .icon svg {
      width: 24px;
      height: 24px;

      @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
        width: 28px;
        height: 28px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
        width: 28px;
        height: 28px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
        width: 32px;
        height: 32px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
        width: 32px;
        height: 32px;
      }
    }

    .feature-item .title {
      @extend %h5;
      color: rgba(#FFFFFF, 0.9);
    }

    .order {
      @extend %navigation;
      color: rgba(#FFFFFF, 0.5);
    }

    .order a {
      display: flex;
      align-items: center;
      color: inherit;
      text-decoration: none;
      transition: color 100ms ease-in-out;
    }

    .order a svg {
      width: 24px;
      height: 24px;
    }

    .order:hover {
      color: rgba(#FFFFFF, 0.9);
    }
  }
}
