%body-font {
  color: #C4C4C4;
  font-size: 9.5px;
  line-height: 12px;
  letter-spacing: 0.5px;

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
    color: #C4C4C4;
    font-size: 9.5px;
    line-height: 12px;
    letter-spacing: 0.5px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.25px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.25px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
    font-size: 13px;
    line-height: 17px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
    font-size: 13px;
    line-height: 17px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
    font-size: 15px;
    line-height: 19px;
  }
}

%h1 {
  color: #C4C4C4;
  font-weight: bold;
  font-feature-settings: 'pnum' on, 'onum' on;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.5px;

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.5px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
    font-size: 30px;
    line-height: 30px;
    letter-spacing: -1px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
    font-size: 30px;
    line-height: 30px;
    letter-spacing: -1px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
    font-size: 38px;
    line-height: 38px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
    font-size: 38px;
    line-height: 38px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
    font-size: 44px;
    line-height: 44px;
    letter-spacing: -2px;
  }
}

%h2 {
  color: #C4C4C4;
  font-feature-settings: 'pnum' on, 'onum' on;
  font-size: 18px;
  line-height: 22px;

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
    font-size: 18px;
    line-height: 22px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -1px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -1px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
    font-size: 36px;
    line-height: 43px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
    font-size: 36px;
    line-height: 43px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -2px;
  }
}

%h3 {
  color: #C4C4C4;
  font-feature-settings: 'pnum' on, 'onum' on;
  font-size: 16px;
  line-height: 16px;

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
    font-size: 16px;
    line-height: 16px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: -0.5px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: -0.5px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
    font-size: 28px;
    line-height: 28px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
    font-size: 28px;
    line-height: 28px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
    font-size: 30px;
    line-height: 30px;
    letter-spacing: -1px;
  }
}

%h4 {
  color: #C4C4C4;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
    font-size: 11px;
    line-height: 13px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
    font-size: 14px;
    line-height: 17px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
    font-size: 14px;
    line-height: 17px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
    font-size: 16px;
    line-height: 19px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
    font-size: 16px;
    line-height: 19px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.5px;
  }
}

%h4-regular {
  color: #C4C4C4;
  font-feature-settings: 'pnum' on, 'onum' on;
  font-size: 11px;
  line-height: 13px;

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
    font-size: 11px;
    line-height: 13px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
    font-size: 14px;
    line-height: 17px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
    font-size: 14px;
    line-height: 17px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
    font-size: 16px;
    line-height: 19px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
    font-size: 16px;
    line-height: 19px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
    font-size: 20px;
    line-height: 24px;
  }
}

%h5 {
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
    font-size: 10px;
    line-height: 12px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
    font-size: 12px;
    line-height: 14px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
    font-size: 12px;
    line-height: 14px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
    font-size: 14px;
    line-height: 17px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
    font-size: 14px;
    line-height: 17px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
    font-size: 15px;
    line-height: 18px;
  }
}

%navigation {
  color: #C4C4C4;
  font-weight: bold;
  font-feature-settings: 'pnum' on, 'onum' on;
  text-transform: uppercase;
  font-size: 8px;
  line-height: 10px;
  letter-spacing: 2px;

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 2px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 3px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 3px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
    font-size: 11px;
    line-height: 13px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
    font-size: 11px;
    line-height: 13px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
    font-size: 12px;
    line-height: 14px;
  }
}

%navigation-2 {
  color: #C4C4C4;
  font-weight: bold;
  font-feature-settings: 'pnum' on, 'onum' on;
  text-transform: uppercase;
  font-size: 7px;
  line-height: 8px;
  letter-spacing: 2px;

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
    font-size: 7px;
    line-height: 8px;
    letter-spacing: 2px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
    font-size: 8px;
    line-height: 10px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
    font-size: 8px;
    line-height: 10px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 2.5px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 2.5px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
    letter-spacing: 2px;
  }
}

%caption {
  color: #C4C4C4;
  font-weight: bold;
  font-feature-settings: 'pnum' on, 'onum' on;
  font-size: 8px;
  line-height: 10px;
  letter-spacing: 1px;

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 1px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.5px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.5px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.5px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.5px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.25px;
  }
}

%caption-regular {
  color: #C4C4C4;
  font-weight: bold;
  font-feature-settings: 'pnum' on, 'onum' on;
  font-size: 8px;
  line-height: 10px;
  letter-spacing: 1px;

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 1px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.5px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.5px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
    font-size: 10px;
    line-height: 12px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
    font-size: 10px;
    line-height: 12px;
  }

  @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.25px;
  }
}