@import "~@/assets/scss/_import.scss";
.sf-press-center-page {
  background: #EDF1F5;

  &__content {
    padding: 81px 41px 68px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    @include min-breakpoint('sm') {
      padding: 96px 54px 79px;
    }

    @include min-breakpoint('md') {
      padding: 113px 75px 101px;
    }

    @include min-breakpoint('lg') {
      padding: 135px 105px 127px;
    }
  }

  &__info {
    margin-bottom: 68px;

    @include min-breakpoint('sm') {
      margin-bottom: 79px;
    }

    @include min-breakpoint('md') {
      margin-bottom: 101px;
    }

    @include min-breakpoint('lg') {
      margin-bottom: 127px;
    }
  }

  &__title {
    color: #284F92;

    margin-bottom: 24px;

    @include min-breakpoint('sm') {
      margin-bottom: 30px;
    }

    @include min-breakpoint('lg') {
      margin-bottom: 75px;
    }
  }

  &__quotes {}

  &__quotes__header {
    .title {
      color: #284F92;
    }

    .exchange-rates {
      @extend %body-font;
      color: #000000;

      > div {}

      > div > span {
        color: #ED1A3A;

        svg {
          width: 10px;
          height: 10px;
        }

        &.growth {
          color: #B8E986;

          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    .date-picker-range .max-input-wrapper {
      width: 100%;
    }
  }

  &__news-item {
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid rgba(#132747, 0.15);
    margin-bottom: 24px;

    .image {
      margin-right: 22px;
    }

    .title {
      margin: 0 0 8px;
      color: #284F92;
    }

    .description {
      @extend %body-font;
      color: rgba(#000000, 0.8);
      margin-bottom: 10px;
    }

    .date {
      @extend %navigation;
      color: rgba(#132747, 0.3);
      margin-bottom: 16px;
    }
  }

  &__chart .caption {
    @extend %caption-regular;
    color: #ACB4C1;
  }

  .bottom-footer {
    height: 400px;
    color: white;
    padding: 50px 0;
    background: radial-gradient(139.91% 125.85% at 79.93% 23.32%, #1668B0 0%, #0B1D37 45.67%, #000000 90.06%);

    ul {
      list-style: none;

      > li {
        @extend %body-font;
        color: rgba(#FFF, 0.8);
        margin-bottom: 16px;

        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }

    .email {
      height: 40px;
      width: 100%;
      max-width: 302px;
      position: relative;
      margin: 0 auto;

      @include min-breakpoint('sm') {
        max-width: 426px;
      }

      @include min-breakpoint('md') {
        height: 48px;
      }

      @include min-breakpoint('lg') {
        max-width: 528px;
      }

      input {
        display: block;
        border: none;
        outline: none;
        width: 100%;
        text-align: center;
        font-weight: 300;
        overflow: visible;
        color: white;
        background-color: rgba(255, 255, 255, .2);
        padding: 13px 22px;
        font-size: 20px;
      }
    }

    .subscriber {
      height: 40px;
      width: 100%;

      @include min-breakpoint('md') {
        height: 48px;
      }

      button {
        cursor: pointer;
        width: 100%;
        height: 100%;
        background: transparent;
        border: 2px solid #40A0D7;
        @extend %navigation;
        color: rgba(#FFF, 0.8);
      }
    }
  }
}
