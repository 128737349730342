@import "~@/assets/scss/_import.scss";




































































































































.fifth-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 4;

  @media screen and (max-width: 479px) {
    flex-direction: column-reverse;
  }

  .text {
    width: 30%;
    background-image: url('~@/assets/images/zavod_1_blur.jpg');
    position: relative;
    z-index: 5;

    @media screen and (max-width: 479px) {
      height: 50%;
      width: 100%;
    }

    .content {
      @include absolute-fill;
      background-color: rgba(#000000, 0.1);
      display: flex;
      flex-direction: column;
      padding: 18.41px 19px 20px 23px;

      @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
        padding: 19px 20px 18px 19px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: portrait) {
        padding: 43.87px 42px 38px 44px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
        padding: 29px 27px 32px 30px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
        padding: 87px 73px 63px 67px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
        padding: 38.94px 35px 41.93px 39.53px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
        padding: 44px 47.27px 47px 53.53px;
      }
    }

    h1 {
      color: #FFFFFF;
    }

    .description {
      @extend %body-font;
    }

    .back {
      display: flex;
      align-items: center;
      @extend %navigation;
      cursor: pointer;
      color: rgba(#FFF, 0.8);
      transition: 100ms ease-in-out;
      position: relative;
      text-decoration: none;

      .inactive,
      .active {
        transition: inherit;
      }

      .inactive {
        position: absolute;
      }

      .active {
        opacity: 0;
      }

      &:hover {
        .active {
          opacity: 1;
        }

        .inactive {
          opacity: 0;
        }
      }
    }

    .feature {
      .number {
        @extend %h2;
        color: #60B3E0;
      }

      .number span {
        @extend %h4;
        color: rgba(#FFF, 0.5);
      }

      .what {
        @extend %h4;
        color: rgba(#FFF, 0.9);
      }
    }

    .quotes {
      position: relative;
      @extend %navigation;
      display: flex;
      align-items: center;
      transition: 100ms ease-in-out;
      cursor: pointer;

      .inactive,
      .active {
        transition: inherit;
      }

      .inactive {
        position: absolute;
      }

      .active {
        opacity: 0;
      }

      &:hover {
        .active {
          opacity: 1;
        }

        .inactive {
          opacity: 0;
        }
      }
    }
  }

  .img {
    position: relative;
    width: 70%;
    height: 100%;

    @media screen and (max-width: 479px) {
      width: 100%;
      height: 50%;
    }
  }

  .background {
    animation: bg 7s linear forwards;
    @include absolute-fill;

    @media screen and (max-width: 479px) {
      top: -30%;
    }

    .backdrop {
      @include absolute-fill;
    }

    .backdrop img:first-child {
      z-index: -1;
      position: absolute;
      width: 100vmax;
      height: 100vh;
    }

    .backdrop img:last-child {
      width: 100vmax;
      height: 100vh;
      position: relative;
      z-index: 2;
    }

    .train {
      @include absolute-fill;
    }

    .train img:first-child {
      position: absolute;
      left: 0;
      top: 0;
      animation: train 50s ease-in-out infinite;
      width: 100vmax;
      height: 100vh;
    }

    .train img:last-child {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vmax;
      height: 100vh;
      animation: train2 50s ease-in-out infinite;
      transform: translate(-42%, 52%);
      animation-delay: 45s;
    }

    .cars .car {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vmax;
      height: 100vh;
      z-index: 1;
    }

    .cars .mazda.down,
    .cars .nissan.down,
    .cars .taxi.down,
    .cars .furgon.down,
    .cars .bus.down {
      transform: translate(25%, -31%);
    }

    .cars .down .bus {
      animation: busDown 80s linear infinite;
      animation-delay: 15s;
    }

    .cars .furgon.down,
    .cars .nissan.down {
      animation: cardDown 40s linear infinite;
      animation-delay: 30s;
    }

    .cars .gaz.down {
      opacity: 0;
      animation: gazDown 40s linear infinite;
    }

    .cars .mazda.down {
      animation: mazdaDown 40s linear infinite;
      animation-delay: 10s;
    }

    .cars .taxi.down {
      animation: carDown 45s linear infinite;
      animation-delay: 60s;
    }

    .cars .mazda.up,
    .cars .nissan.up,
    .cars .taxi.up,
    .cars .furgon.up,
    .cars .bus.up {
      transform: translate(-19%, 24%);
    }

    .cars .bus.up {
      animation: busUp 80s linear infinite;
      animation-delay: 5s;
    }

    .cars .furgon.up,
    .cars .nissan.up {
      animation: carUp 40s linear infinite;
      animation-delay: 20s;
    }

    .cars .gaz.up {
      animation: gazUp 40s linear infinite;
      animation-delay: 300ms;
    }

    .cars .mazda.up {
      animation: mazdaUp 40s linear infinite;
    }

    .cars .taxi.up {
      animation: carUp 45s linear infinite;
      animation-delay: 50s;
    }
  }
}

@keyframes bg {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

@keyframes train {
  0% {
    transform: translate(-35%, 44%);
  }

  25% {
    transform: translate(0, 0);
  }

  45% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(56%, -70%);
  }
}

@keyframes train2 {
  0% {
    transform: translate(-42%, 52%);
  }

  10% {
    transform: translate(-35%, 44%);
  }

  45% {
    transform: translateX(0) translateY(0);
  }

  65% {
    transform: translateX(0) translateY(0);
  }

  100% {
    transform: translate(56%, -70%);
  }
}

@keyframes busDown {
  0% {
    opacity: 1;
    transform: translate(25%, -31%);
  }

  33% {
    opacity: 1;
    transform: translate(-47%, 59%);
  }

  65.9% {
    opacity: 1;
    transform: translate(-47%, 59%)
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translate(25%, -31%);
  }
}

@keyframes carDown {
  0% {
    opacity: 1;
    transform: translate(25%, -31%);
  }

  50% {
    opacity: 1;
    transform: translate(-47%, 59%);
  }

  51% {
    opacity: 0;
  }

  99% {
    opacity: 0;
    transform: translate(25%, -31%);
  }

  100% {
    opacity: 1;
  }
}

@keyframes gazDown {
  0% {
    opacity: 0;
    transform: translate(56%, -70%);
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  88.9% {
    opacity: 1;
    transform: translate(-17%, 21%);
  }

  98.9% {
    opacity: 1;
    transform: translate(-17%, 21%);
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translate(56%, -70%);
  }
}

@keyframes mazdaDown {
  0% {
    opacity: 1;
    transform: translate(25%, -31%);
  }

  50% {
    opacity: 1;
    transform: translate(-47%, 59%);
  }

  51% {
    opacity: 0;
  }

  99% {
    opacity: 0;
    transform: translate(25%, -31%);
  }

  100% {
    opacity: 1;
  }
}

@keyframes busUp {
  0% {
    opacity: 1;
    transform: translate(-17%, 21%);
  }

  33% {
    opacity: 1;
    transform: translate(56%, -70%);
  }

  65.9% {
    opacity: 1;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translate(56%, -70%);
  }
}

@keyframes carUp {
  0% {
    opacity: 1;
    transform: translate(-19%, 24%);
  }

  4% {
    transform: translate(-17%, 21%);
  }

  50% {
    opacity: 1;
    transform: translate(56%, -70%);
  }

  51% {
    opacity: 0;
  }

  99% {
    opacity: 0;
    transform: translate(-19%, 24%);
  }

  100% {
    opacity: 1;
  }
}

@keyframes gazUp {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }

  50% {
    opacity: 1;
    transform: translate(56%, -70%);
  }

  51% {
    opacity: 0;
  }

  99% {
    opacity: 0;
    transform: translate(0, 0);
  }

  100% {
    opacity: 1;
  }
}

@keyframes mazdaUp {
  0% {
    opacity: 1;
    transform: translate(-17%, 21%);
  }

  50% {
    opacity: 1;
    transform: translate(56%, -70%);
  }

  51% {
    opacity: 0;
  }

  99% {
    opacity: 0;
    transform: translate(-17%, 21%);
  }

  100% {
    opacity: 1;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

