.container {
  width: 100%;
  padding: {
    right: $grid-gutter-width / 2;
    left: $grid-gutter-width / 2;
  };
  margin: {
    right: auto;
    left: auto;
  };
  &:not(.full) {
    @include media-query((
            max-width: (
                    sm: map_get($breakpoint-max-width, sm),
                    md: map_get($breakpoint-max-width, md),
                    lg: map_get($breakpoint-max-width, lg)
            )
    ));
  }

  &.container--no-padding {
    padding: {
      left: 0;
      right: 0;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: {
    right: -$grid-gutter-width / 2;
    left: -$grid-gutter-width / 2;
  };
  &.no-gutters {
    margin: {
      right: 0;
      left: 0;
    };
    > .flex {
      padding: {
        right: 0;
        left: 0;
      };
    }
  }
  &.no-gutters-child {
    &--padding {
      > .flex:not(:first-of-type) {
        padding: {
          left: $grid-gutter-width;
        }
      }
    }
    > .flex {
      padding: {
        left: 0;
        right: 0;
      }
    }
  }
}

.flex {
  position: relative;
  width: 100%;
  padding: {
    right: $grid-gutter-width / 2;
    left: $grid-gutter-width / 2;
  };
  @each $breakpoint, $width in $grid-breakpoint {
    @media screen and (min-width: $width) {
      @for $i from 1 through $grid-columns {
        &.#{$breakpoint}#{$i} {
          flex: 0 0 percentage($i / $grid-columns);
          max-width: percentage($i / $grid-columns);
        }
      }
      @for $i from 1 to $grid-columns {
        &.offset-#{$breakpoint}#{$i} {
          margin: {
            left: percentage($i / $grid-columns);
          };
        }
      }

      @for $i from 0 to $grid-columns {
        &.r-offset-#{$breakpoint}#{$i} {
          margin-right: percentage($i / $grid-columns);
        }
      }

      @for $i from 1 through $grid-columns {
        &.order-#{$breakpoint}#{$i} {
          order: $i;
        }
      }
    }

    @media screen and (min-width: $width) and (orientation: landscape) {
      @for $i from 1 through $grid-columns {
        &.l-#{$breakpoint}#{$i} {
          flex: 0 0 percentage($i / $grid-columns) !important;
          max-width: percentage($i / $grid-columns) !important;
        }
      }
      @for $i from 1 to $grid-columns {
        &.l-offset-#{$breakpoint}#{$i} {
          margin: {
            left: percentage($i / $grid-columns);
          };
        }
      }

      @for $i from 0 to $grid-columns {
        &.l-r-offset-#{$breakpoint}#{$i} {
          margin-right: percentage($i / $grid-columns);
        }
      }

      @for $i from 1 through $grid-columns {
        &.l-order-#{$breakpoint}#{$i} {
          order: $i;
        }
      }
    }
  }
}