@import "~@/assets/scss/_import.scss";























































































.sf-icon {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  vertical-align: middle;
}
