$i: 0;

@each $breakpoint, $width in $grid-breakpoint {
  @media screen and (min-width: $width) {
    $infix: if($breakpoint == xs, unquote(''), -#{$breakpoint});
    @while $i <= $max-indentation {
      .m {
        &t#{$infix}-#{$i} {
          margin: { top: #{$i}px !important; };
        }
        &r#{$infix}-#{$i} {
          margin: { right: #{$i}px !important; };
        }
        &b#{$infix}-#{$i} {
          margin: { bottom: #{$i}px !important; };
        }
        &l#{$infix}-#{$i} {
          margin: { left: #{$i}px !important; };
        }
        &x#{$infix}-#{$i} {
          margin: {
            left: #{$i}px !important;
            right: #{$i}px !important;
          };
        }
        &y#{$infix}-#{$i} {
          margin: {
            top: #{$i}px !important;
            bottom: #{$i}px !important;
          };
        }
        &a#{$infix}-#{$i} {
          margin: #{$i}px !important;
        }
      }
      .p {
        &t#{$infix}-#{$i} {
          padding: { top: #{$i}px !important; };
        }
        &r#{$infix}-#{$i} {
          padding: { right: #{$i}px !important; };
        }
        &b#{$infix}-#{$i} {
          padding: { bottom: #{$i}px !important; };
        }
        &l#{$infix}-#{$i} {
          padding: { left: #{$i}px !important; };
        }
        &x#{$infix}-#{$i} {
          padding: {
            left: #{$i}px !important;
            right: #{$i}px !important;
          };
        }
        &y#{$infix}-#{$i} {
          padding: {
            top: #{$i}px !important;
            bottom: #{$i}px !important;
          };
        }
        &a#{$infix}-#{$i} {
          padding: #{$i}px !important;
        }
      }
      $i: $i + 4;
    }

    $i: 0;

    .m {
      &t#{$infix}-auto {
        margin: { top: auto !important; };
      }
      &r#{$infix}-auto {
        margin: { right: auto !important; };
      }
      &b#{$infix}-auto {
        margin: { bottom: auto !important; };
      }
      &l#{$infix}-auto {
        margin: { left: auto !important; };
      }
      &x#{$infix}-auto {
        margin: {
          left: auto !important;
          right: auto !important;
        };
      }
      &y#{$infix}-auto {
        margin: {
          top: auto !important;
          bottom: auto !important;
        };
      }
      &a#{$infix}-auto {
        margin: auto !important;
      }
    }
  }

  $i: 0;

  @media screen and (min-width: $width) and (orientation: landscape) {
    $infix: if($breakpoint == xs, unquote(''), -#{$breakpoint});
    @while $i <= $max-indentation {
      .m {
        &t#{$infix}-#{$i}--l {
          margin: { top: #{$i}px !important; };
        }
        &r#{$infix}-#{$i}--l {
          margin: { right: #{$i}px !important; };
        }
        &b#{$infix}-#{$i}--l {
          margin: { bottom: #{$i}px !important; };
        }
        &l#{$infix}-#{$i}--l {
          margin: { left: #{$i}px !important; };
        }
        &x#{$infix}-#{$i}--l {
          margin: {
            left: #{$i}px !important;
            right: #{$i}px !important;
          };
        }
        &y#{$infix}-#{$i}--l {
          margin: {
            top: #{$i}px !important;
            bottom: #{$i}px !important;
          };
        }
        &a#{$infix}-#{$i}--l {
          margin: #{$i}px !important;
        }
      }
      .p {
        &t#{$infix}-#{$i}--l {
          padding: { top: #{$i}px !important; };
        }
        &r#{$infix}-#{$i}--l {
          padding: { right: #{$i}px !important; };
        }
        &b#{$infix}-#{$i}--l {
          padding: { bottom: #{$i}px !important; };
        }
        &l#{$infix}-#{$i}--l {
          padding: { left: #{$i}px !important; };
        }
        &x#{$infix}-#{$i}--l {
          padding: {
            left: #{$i}px !important;
            right: #{$i}px !important;
          };
        }
        &y#{$infix}-#{$i}--l {
          padding: {
            top: #{$i}px !important;
            bottom: #{$i}px !important;
          };
        }
        &a#{$infix}-#{$i}--l {
          padding: #{$i}px !important;
        }
      }
      $i: $i + 4;
    }

    $i: 0;

    .m {
      &t#{$infix}-auto--l {
        margin: { top: auto !important; };
      }
      &r#{$infix}-auto--l {
        margin: { right: auto !important; };
      }
      &b#{$infix}-auto--l {
        margin: { bottom: auto !important; };
      }
      &l#{$infix}-auto--l {
        margin: { left: auto !important; };
      }
      &x#{$infix}-auto--l {
        margin: {
          left: auto !important;
          right: auto !important;
        };
      }
      &y#{$infix}-auto--l {
        margin: {
          top: auto !important;
          bottom: auto !important;
        };
      }
      &a#{$infix}-auto--l {
        margin: auto !important;
      }
    }
  }
}
