@import "~@/assets/scss/_import.scss";











































































































































































.sf-header {
  z-index: 3;
}
.sf-menu {
  z-index: 2;
}
.contacts-content {
  overflow: auto;
  position: relative;
  z-index: 1;

  .contacts {
    background: #EDF1F5;
    margin-top: 127px;
    overflow: visible;
    padding-bottom: 61px;

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
      margin-top: 127px;
      padding-bottom: 73px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) {
      margin-top: 127px;
      padding-bottom: 73px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
      margin-top: 127px;
      padding-bottom: 89px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) {
      margin-top: 158px;
      padding-bottom: 109px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
      margin-top: 158px;
      padding-bottom: 109px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
      margin-top: 189px;
      padding-bottom: 168px;
    }
  }

  .contact-frame {
    background: #FFFFFF;
    box-shadow: 0 0 20px rgba(34, 34, 34, 0.07);
    position: relative;
    top: -57px;
    margin-left: 42px;
    margin-right: 42px;
    margin-bottom: 3px;

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
      top: -57px;
      margin-left: 54px;
      margin-right: 54px;
      margin-bottom: 7px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) {
      top: -36px;
      margin-left: 54px;
      margin-right: 54px;
      margin-bottom: 30px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
      top: -36px;
      margin-left: 54px;
      margin-right: 54px;
      margin-bottom: 36px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) {
      top: -50px;
      margin-left: 73px;
      margin-right: 73px;
      margin-bottom: 28px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
      top: -50px;
      margin-left: 87px;
      margin-right: 87px;
      margin-bottom: 43px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
      top: -61px;
      max-width: 1026px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 61px;
    }
  }

  .contact-frame__content {
    background: url("~@/assets/images/map-dot.png") no-repeat;
    width: 100%;
    height: 100%;
    padding: 22px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
      padding: 30px 34px 81px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) {
      padding: 33px 34px 29px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
      padding: 33px 34px 27px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) {
      padding: 47px 34px 41px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
      padding: 47px 40px 39px 48px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
      padding: 59px 56px 39px 68px;
    }

    h1.title span {
      display: block;
      color: #284F92;

      &:first-child {
        @extend %h3;
        color: #284F92;
      }
    }
  }

  .contact-item {
    .city {
      @extend %h4;
      color: #284F92;

      span {
        @extend %body-font;
        color: #284F92;
      }
    }

    .caption {
      @extend %navigation-2;
      color: #B7B7B7;
    }

    .value {
      @extend %body-font;
      color: rgba(#000000, 0.9);
    }

    .value a {
      color: inherit;
      text-decoration: none;
    }

    .to-map {
      display: flex;
      align-items: center;
      color: #40A0D7;
      cursor: pointer;
    }

    .to-map svg {
      width: 24px;
      height: 24px;
    }

    .to-map span {
      @extend %h5;
      color: #40A0D7;
    }
  }

  .contact-us {
    h1.title {
      color: #284F92;
      text-align: center;
    }

    h4.subtitle {
      color: #000000;
      text-align: center;
    }

    .input-group {
      width: 100%;
      max-width: 528px;
      margin: 0 auto;

      position: relative;

      input {
        @extend %h5;
        color: #888888;
        padding: 11px;
        display: block;
        width: 100%;
        border: none;
        border-bottom: 1px solid #D8D8D8;
        background: transparent;
        text-align: center;
        font-size: 11px/120%;
        letter-spacing: 3px;
        text-transform: uppercase;

        @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
          padding: 11px;
          font-size: 11px/120%;
          letter-spacing: 3px;
        }

        @media screen and (min-width: map-get($grid-breakpoint, 'sm')) {
          padding: 18px;
          font-size: 14px/120%;
          letter-spacing: 3px;
        }

        @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
          padding: 18px;
          font-size: 14px/120%;
          letter-spacing: 3px;
        }

        @media screen and (min-width: map-get($grid-breakpoint, 'md')) {
          padding: 15px;
          font-size: 16px/120%;
          letter-spacing: 4px;
        }

        @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
          padding: 15px;
          font-size: 16px/120%;
          letter-spacing: 4px;
        }

        @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
          padding: 13px;
          font-size: 18px/120%;
          letter-spacing: 4px;
        }
      }

      input:focus {
        outline: none;
      }

      label {
        color: #999;
        font-size: 18px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 10px;
        transition: 0.2s ease all;
      }

      input:focus ~ label,
      input:valid ~ label {
        top: -20px;
        font-size: 14px;
        color: #5264AE;
      }

      .bar {
        position: relative;
        display: block;
        width: 100%;
      }

      .bar:before, .bar:after {
        content: '';
        height: 2px;
        width: 0;
        bottom: 1px;
        position: absolute;
        background: #5264AE;
        transition: 0.2s ease all;
      }

      .bar:before {
        left: 50%;
      }

      .bar:after {
        right: 50%;
      }

      /* active state */
      input:focus ~ .bar:before, input:focus ~ .bar:after {
        width: 50%;
      }

      /* HIGHLIGHTER ================================== */
      .highlight {
        position: absolute;
        height: 60%;
        width: 100%;
        top: 25%;
        left: 0;
        pointer-events: none;
        opacity: 0.5;
      }

      /* active state */
      input:focus ~ .highlight {
        -webkit-animation: inputHighlighter 0.3s ease;
        -moz-animation: inputHighlighter 0.3s ease;
        animation: inputHighlighter 0.3s ease;
      }

      /* ANIMATIONS ================ */
      @-webkit-keyframes inputHighlighter {
        from {
          background: #5264AE;
        }
        to {
          width: 0;
          background: transparent;
        }
      }
      @-moz-keyframes inputHighlighter {
        from {
          background: #5264AE;
        }
        to {
          width: 0;
          background: transparent;
        }
      }
      @keyframes inputHighlighter {
        from {
          background: #5264AE;
        }
        to {
          width: 0;
          background: transparent;
        }
      }
    }
  }

  .call-me-button {
    margin: 0 auto;
    width: 236px;

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
      width: 304px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) {
      width: 304px;
    }

    button {
      height: 40px;
      width: 100%;
      background: #40A0D7;
      border: 2px solid #40A0D7;
      box-sizing: border-box;
      border-radius: 1px;
      @extend %navigation;
      color: rgba(#FFFFFF, 0.8);
      cursor: pointer;
      transition: 200ms ease-in-out;

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) {
        height: 48px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
        height: 48px;
      }

      &:hover {
        background: linear-gradient(0deg, #40A0D7, #40A0D7);
        box-shadow: 0 0 16px #63DCFF, 0 0 24px #63DCFF;
        color: #FFFFFF;
      }
    }
  }

  footer.footer {
    .container {
      padding: 50px 44px 44px;

      @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
        padding: 55px 52px 44px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'sm')) {
        padding: 62px 52px 54px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
        padding: 59px 52px 42px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) {
        padding: 69px 73px 60px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
        padding: 69px 87px 60px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
        padding: 68px 104px 69px;
      }
    }

    ul.menu-link {
      list-style: none;

      > li {
        @extend %body-font;
        color: rgba(#FFFFFF, 0.8);
        transition: 200ms ease-in-out;
        margin-bottom: 16px;

        a {
          color: inherit;
          text-decoration: none;
        }

        &:hover {
          color: #FFFFFF;
        }
      }
    }

    input.email-input {
      width: 100%;
      height: 40px;
      padding: 13px;
      outline: none;
      @extend %body-font;
      border: none;
      background: rgba(#FFFFFF, 0.2);
      color: #FFFFFF;

      &::placeholder {
        color: rgba(#FFFFFF, 0.8);
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) {
        height: 48px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
        height: 48px;
      }
    }

    button.subscriber {
      width: 100%;
      height: 40px;
      border: 2px solid #40A0D7;
      border-radius: 1px;
      background: transparent;
      @extend %navigation;
      color: rgba(#FFFFFF, 0.8);
      cursor: pointer;
      transition: 200ms ease-in-out;
      outline: none;

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) {
        height: 48px;
      }

      @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
        height: 48px;
      }

      &:hover {
        box-shadow: 0 0 16px #63DCFF, 0 0 24px #63DCFF;
        color: #FFFFFF;
      }
    }

    .copyright {
      @extend %caption-regular;
      color: rgba(#FFFFFF, 0.3);
    }

    .download-presentation a {
      display: flex;
      align-items: center;
      color: #40A0D7;
      text-decoration: none;

      @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
        justify-content: flex-end;
      }

      svg {
        width: 24px;
        height: 24px;
      }

      span {
        @extend %navigation;
        color: #40A0D7;
        border-bottom: 1px dashed;
      }
    }
  }
}
