@import "~@/assets/scss/_import.scss";
$heightScreen: 952;
$widthScreen: 1440;

@function toVH ($size) {
  @return ($size / $heightScreen * 100vh);
}

@function toVW ($size) {
  @return ($size / $widthScreen * 100vw);
}

.sf-product-page {
  &__main {
    position: relative;
    transition: 200ms cubic-bezier(0.31, 0.62, 0, 1);
  }

  .b-block__section {
    display: flex;
    height: 100%;
    position: relative;
    flex-wrap: wrap;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background: #EDF1F5;
      width: 75%;
      pointer-events: none;
    }

    &__left-column,
    &__right-column {
      width: 100%;
      @media screen and (min-width: 768px)  {
        max-width: 50%;
        flex-basis: 50%;
      }

      max-width: 100%;
      flex-basis: 100%;
      position: relative;
      z-index: 2;
    }

    &__right-column {
      align-items: flex-end;

      @media screen and (min-width: 768px) {
        align-items: stretch;
      }
    }

    &__image {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 32px;
      z-index: 1;
      img {
        width: toVH(704);
      }
    }
    &__card {
      position: absolute;
      top: toVH(174);
      right: 0;
      width: 286px;
      height: 286px;
      background: #FFFFFF;
      box-shadow: 0 16px 32px rgba(#2C323C, 0.1);
      padding: 32px;
      span {
        display: block;
        font-size: 20px;
        line-height: 21px;
        font-weight: bold;
        //font: bold 20px/21px $fontCond;
        letter-spacing: -0.02em;
        color: #2B7AEE;
      }
    }
    &__right-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 toVW(75);
    }
    &__title {
      font: 900 #{toVH(60)}/#{toVH(68)} $font;
      letter-spacing: -0.05em;
      color: #0F3B91;
      margin-bottom: toVH(43);
      width: 100%;
      max-width: toVH(479);
    }
    &__description {
      @extend %body-font;
      color: rgba(#2C323C, 0.9);
      margin-bottom: toVH(52);
      width: 100%;
      max-width: toVH(501);
    }
    &__footer {
      display: flex;
      margin-bottom: toVH(46);
      width: 100%;
      max-width: toVH(501);
    }

    &__price {
      &__caption,
      &__value {
        font: 500 italic #{toVH(18)}/#{toVH(24)} $font;
        letter-spacing: -0.03em;
        color: rgba(#2C323C, 0.5);
      }
      &__value {
        color: rgba(#2C323C, 0.8);
        span {
          font: bold italic #{toVH(36)} $font;
        }
      }
    }
    &__link {
      margin-left: auto;
      a {
        font: bold #{toVH(14)}/#{toVH(17)} $font;
        letter-spacing: 0.21em;
        text-transform: uppercase;
        color: #2B7AEE;
        border-bottom: 1px dashed #2B7AEE;
        text-decoration: none;
        transition: 200ms cubic-bezier(0.31, 0.62, 0, 1);
        &:hover {
          color: #6EA9FF;
          border-bottom-color: transparent;
        }
      }
    }
    &__contacts {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 40%;
      background: rgba(#DAE9FC, 0.9);
      z-index: 4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition: 200ms cubic-bezier(0.31, 0.62, 0, 1);
      padding-left: toVH(149);
      &.hide {
        transform: translateX(calc(-100% + 52px)) translateY(-50%);
        top: 50%;
        height: 50px;
        border-radius: 25px;
      }
      &__title {
        font: 900 #{toVH(60)}/#{toVH(68)} $font;
        letter-spacing: -0.05em;
        color: #0F3B91;
        width: 100%;
        max-width: toVH(363);
        margin-bottom: toVH(41);
      }
      &__arrow {
        width: 11px;
        height: 14px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDFMMS40Mjk0MyA2LjEzODUzQzAuNzgxOTY5IDYuNTI2NzIgMC43ODE2NjQgNy40NjQ5IDEuNDI4ODggNy44NTM1MkwxMCAxMyIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjwvcGF0aD4KPC9zdmc+");
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: 200ms cubic-bezier(0.31, 0.62, 0, 1);
        &.hide {
          transform: translateY(-50%) rotate(180deg);
        }
      }
      &__addresses {
        width: 100%;
        max-width: toVH(363);
        .name {
          font: bold #{toVH(18)}/#{toVH(25)} $font;
          letter-spacing: -0.04em;
          color: rgba(#2C323C, 0.8);
          margin-bottom: toVH(13);
        }
        .address {
          font: #{toVH(18)}/#{toVH(25)} $font;
          letter-spacing: -0.04em;
          color: rgba(#2C323C, 0.8);
          margin-bottom: toVH(12);
        }
        .phone {
          font: bold #{toVH(18)}/#{toVH(25)} $font;
          letter-spacing: -0.04em;
          color: rgba(#2C323C, 0.8);
          margin-bottom: toVH(8);
        }
        .email {
          font: bold #{toVH(18)}/#{toVH(25)} $font;
          letter-spacing: -0.04em;
          color: rgba(#2C323C, 0.8);
          margin-bottom: toVH(41);
        }
      }
    }
    &.footer {
      background: #2C323C;
    }

    .button--action {
      cursor: pointer;
      width: toVW(320);
      height: toVH(64);
      background: #E0310B;
      border-radius: 2px;
      font: bold #{toVH(16)} $font;
      text-align: center;
      letter-spacing: 0.29em;
      text-transform: uppercase;
      color: #FFFFFF;
      transition: 200ms cubic-bezier(0.31, 0.62, 0, 1);
      border: none;
      outline: none;
      &:hover {
        background: #FF5732;
        box-shadow: 0 0 32px #FFA28D;
      }
    }
  }

  h1 {
    color: #284F92;
    margin-bottom: 32px;
  }

  h3 {
    color: #000000;
    margin-bottom: 32px;
  }

  h4 {
    margin-top: 32px;
    margin-bottom: 16px;
    color: #000000;
  }

  .presentation {
    display: flex;
    align-items: center;
    margin-top: 16px;
    transition: 200ms ease-in-out;
    color: rgba(#284F92, 0.9);

    img {
      margin-right: 12px;
      transition: inherit;
    }

    a {
      @extend %navigation;
      color: inherit;
      text-decoration-style: dotted;
      transition: inherit;
    }

    &:hover {
      color: rgba(#40A0D7, 0.9);
    }
  }
}
