@font-face {
  font-family: 'Russo Sans';
  src: url('~@/assets/fonts/russosans_regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Russo Sans';
  src: url('~@/assets/fonts/russosans_bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}