@import "~@/assets/scss/_import.scss";






































































































































































































































.sf-history-page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-blend-mode: multiply, normal;
  background: radial-gradient(96.79% 178.05% at 27.16% 67.35%, #1668B0 0%, #0B1D37 41.44%, #000000 90.06%);

  .section-background {
    @include absolute-fill;
    transition: opacity 300ms ease-in-out;
    background-blend-mode: multiply, normal;
    background-size: cover !important;

    &.hide {
      opacity: 0;
    }
  }

  .container {
    padding: 27px 16px;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr 1fr;
    position: relative;
    z-index: 2;

    @include min-breakpoint('sm') {
      padding: 48px 32px;
    }

    @include min-breakpoint('md') {
      padding: 64px 120px;
    }

    @include min-breakpoint('lg') {
      padding: 84px 124px;
    }

    .title {
      grid-row: 1/2;
      grid-column: 2/3;

      @include min-breakpoint-landscape('sm') {
        grid-column: 1/2;
      }
    }

    .line {
      grid-row: 1/3;
      grid-column: 1/2;

      @include min-breakpoint-landscape('sm') {
        grid-row: 2/3;
      }
    }

    .text {
      grid-row: 2/3;
      grid-column: 2/3;

      @include min-breakpoint-landscape('sm') {
        grid-row: 1/3;
      }
    }
  }

  .fixed-title {
    position: fixed;
  }

  &__title {
    @extend %h1;
    color: #40A0D7;
    text-shadow: 0 0 32px rgba(82, 194, 255, 0.5);
    margin: 0;
  }

  &__text {
    color: rgba(#FFF, 0.9);

    &::v-deep > * {
      margin: 0;
    }

    &::v-deep .subtitle {
      @extend %h4;
      color: inherit;
    }

    &::v-deep .regular {
      @extend %body-font;
      color: inherit;
    }

    &::v-deep .orange {
      @extend %h4;
      color: rgba(#F5DF6A, 0.9);
    }

    &::v-deep a {
      display: flex;
      align-items: center;
      @extend %navigation;
      color: rgba(#FFF, 0.5);
      text-decoration: none;
      transition: all 300ms ease-in-out;

      &:hover {
        color: rgba(#FFF, 0.9);
      }
    }
  }

  .timeline {
    position: fixed;
    bottom: 84px;
    left: 124px;
  }

  .timeline-item {
    width: 4px;
    height: 1px;
    background-color: rgba(#6FBBE7, 0.7);
    margin-bottom: 16px;
    position: relative;
    transition: all 100ms ease-in-out;

    &.is-clickable {
      cursor: pointer;
    }

    span {
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      @extend %h4;
      color: rgba(#FFF, 0.5);
      transition: inherit;
    }

    &.active {
      width: 16px;

      span {
        @extend %h2;
        color: #FFF;
        left: 24px;
      }
    }
  }
}
