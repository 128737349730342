@import "~@/assets/scss/_import.scss";














































.sf-header {
  @include absolute-fill;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: map-get($header-height, 'xs');
  padding: map-get($header-padding, 'xs');
  background: rgba(#0B1C36, 0.5);
  z-index: 2;

  @include min-breakpoint('sm') {
    height: map-get($header-height, 'sm');
  }

  @include min-breakpoint('md') {
    height: map-get($header-height, 'md');
    padding: map-get($header-padding, 'md');
  }

  @include min-breakpoint('lg') {
    height: map-get($header-height, 'lg');
  }

  .leading {
    display: flex;
    align-items: center;
    position: relative;
  }

  .menu {
    width: 22px;
    height: 23px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    align-items: center;
    opacity: 0.5;
    cursor: pointer;
    transition: all 100ms ease;
    margin-right: 8px;
    position: relative;

    @include min-breakpoint('sm') {
      margin-right: 16px;
    }

    span {
      background-color: #FFFFFF;
      height: 2px;
    }

    &:hover {
      height: 32px;
      opacity: 1;

      span {
        box-shadow: 0 0 4px #B1DAF0;
      }
    }
  }

  .search {
    width: 23px;
    height: 20px;
    opacity: 0.5;
    cursor: pointer;
    transition: all .1s ease;
    position: relative;

    span:first-child {
      position: absolute;
      top: 0;
      left: 0;
      width: 19px;
      height: 19px;
      border: 2px solid #FFFFFF;
      border-radius: 50%;
    }

    span:last-child {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 8px;
      height: 2px;
      background-color: #FFFFFF;
      transform: rotate(45deg);
    }

    &.hide {
      display: none;
    }

    &:hover {
      opacity: 1;

      span {
        box-shadow: 0 0 4px #B1DAF0;
      }
    }
  }

  .search-input {
    background-color: #FFFFFF;
    width: 100%;
    max-width: 320px;
    height: 40px;
    display: none;
    align-items: center;

    .search {
      left: 8px;
      transform: none;

      span:first-child {
        background-color: #FFFFFF;
        border: 2px solid rgba(#000000, 0.5);
      }

      span:last-child {
        background-color: rgba(#000000, 0.5);
        height: 2px;
      }
    }

    input {
      color: #000000;
      border: none;
      padding-left: 40px;
      outline: none;
    }

    &.show {
      display: flex;
    }
  }

  .logo {
    height: map-get($header-logo-height, 'xs');

    @include min-breakpoint('sm') {
      height: map-get($header-logo-height, 'sm');
    }

    @include min-breakpoint('md') {
      height: map-get($header-logo-height, 'md');
    }

    @include min-breakpoint('lg') {
      height: map-get($header-logo-height, 'lg');
    }

    img {
      height: 100%;
    }
  }

  .langs {
    @extend %navigation-2;
    color: #FFFFFF;

    .divider {
      height: 12px;
      width: 2px;
      background-color: #FFFFFF;
      opacity: 0.3;
      display: inline-block;
      position: relative;
      top: 2px;
      margin: 0 7px;
    }

    .lang {
      opacity: 0.3;
      display: inline-block;
      transition: all 100ms ease;
      cursor: pointer;

      &:hover,
      &.active {
        opacity: 1;
      }

      &:hover {
        text-shadow: 0 0 16px #60B3E0, 0 0 4px #B1DAF0;
      }
    }
  }

  &.open-menu {
    .search,
    .search-input,
    .langs {
      opacity: 0;
      pointer-events: none;
    }

    .menu {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      align-items: center;
      opacity: 1;
      height: 23px !important;
    }

    .menu span {
      &:nth-child(1) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 3px;
      }

      &:nth-child(2) {
        position: absolute;
        top: 15px;
        transform: translateY(-50%) rotate(45deg);
        width: 40%;
      }

      &:nth-child(3) {
        position: absolute;
        top: 7px;
        transform: translateY(-50%) rotate(-45deg);
        width: 40%;
      }

      &:nth-child(4) {
        display: none;
      }
    }
  }
}
