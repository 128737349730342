@import "~@/assets/scss/_import.scss";









































































.sf-news-item-page {
  background: #EDF1F5;

  &__content {
    padding: 81px 41px 68px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    @include min-breakpoint('sm') {
      padding: 96px 54px 79px;
    }

    @include min-breakpoint('md') {
      padding: 113px 75px 101px;
    }

    @include min-breakpoint('lg') {
      padding: 135px 105px 127px;
    }
  }

  h1.title {
    color: #284F92;
  }

  .date {
    @extend %navigation;
    color: rgba(#132747, 0.3);
  }

  .info {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
      flex-direction: row;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: portrait) {
      flex-direction: row;
    }
  }

  .picture {
    img {
      width: 167px;

      @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
        width: 240px;
      }
    }

    .picture-caption {
      @extend %caption-regular;
      color: #979797;
    }
  }

  .text {
    @extend %body-font;
    color: rgba(#000, 0.8);
  }

  .actions {
    display: flex;
    button {
      display: block;
      background: transparent;
      border: none;
      outline: none;
      @extend %navigation;
      color: #284F92;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  .bottom-footer {
    height: 400px;
    color: white;
    padding: 50px 0;
    background: radial-gradient(139.91% 125.85% at 79.93% 23.32%, #1668B0 0%, #0B1D37 45.67%, #000000 90.06%);

    ul {
      list-style: none;

      > li {
        @extend %body-font;
        color: rgba(#FFF, 0.8);
        margin-bottom: 16px;

        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }

    .email {
      height: 40px;
      width: 100%;
      max-width: 302px;
      position: relative;
      margin: 0 auto;

      @include min-breakpoint('sm') {
        max-width: 426px;
      }

      @include min-breakpoint('md') {
        height: 48px;
      }

      @include min-breakpoint('lg') {
        max-width: 528px;
      }

      input {
        display: block;
        border: none;
        outline: none;
        width: 100%;
        text-align: center;
        font-weight: 300;
        overflow: visible;
        color: white;
        background-color: rgba(255, 255, 255, .2);
        padding: 13px 22px;
        font-size: 20px;
      }
    }

    .subscriber {
      height: 40px;
      width: 100%;

      @include min-breakpoint('md') {
        height: 48px;
      }

      button {
        cursor: pointer;
        width: 100%;
        height: 100%;
        background: transparent;
        border: 2px solid #40A0D7;
        @extend %navigation;
        color: rgba(#FFF, 0.8);
      }
    }
  }
}
