@import "~@/assets/scss/_import.scss";





































































.sf-map {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #FFF;

  iframe {
    width: 100%;
    height: 100%;
  }

  .contact-frame {
    background: #FFFFFF;
    box-shadow: 0 0 20px rgba(34, 34, 34, 0.07);
    position: fixed;
    z-index: 11;

    bottom: 0;
    left: 0;
    right: 0;
    height: 189px;

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
      bottom: 0;
      left: 0;
      top: 0;
      height: auto;
      width: 160px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) {
      top: 8px;
      left: 8px;
      width: 189px;
      height: 438px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
      top: 20px;
      left: 20px;
      width: 189px;
      height: 438px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) {
      width: 260px;
      height: 564px;
      top: 40px;
      left: 40px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
      width: 260px;
      height: 564px;
      top: 40px;
      left: 40px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
      width: 300px;
      height: 644px;
    }
  }

  .contact-frame__content {
    background: url("~@/assets/images/map-dot.png") no-repeat;
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) and (orientation: landscape) {
      padding: 16px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'sm')) {
      padding: 22px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) and (orientation: landscape) {
      padding: 22px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'md')) {
      padding: 32px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'lg')) and (orientation: landscape) {
      padding: 32px;
    }

    @media screen and (min-width: map-get($grid-breakpoint, 'xl')) and (orientation: landscape) {
      padding: 32px;
    }

    .back {
      display: flex;
      align-items: center;
      @extend %navigation;
      color: rgba(#265295, 0.5);
      cursor: pointer;

      svg {
        width: 16px;
      }

      span {
        display: block;
        margin-left: 16px;
      }
    }

    h1.title span {
      display: block;
      color: #284F92;

      &:first-child {
        @extend %h3;
        color: #284F92;
      }
    }
  }

  .contact-item {
    .city {
      @extend %h4;
      color: #284F92;

      span {
        @extend %body-font;
        color: #284F92;
      }
    }

    .caption {
      @extend %navigation-2;
      color: #B7B7B7;
    }

    .value {
      @extend %body-font;
      color: rgba(#000000, 0.9);
    }

    .value a {
      color: inherit;
      text-decoration: none;
    }

    .to-map {
      display: flex;
      align-items: center;
      color: #40A0D7;
      cursor: pointer;
    }

    .to-map svg {
      width: 24px;
      height: 24px;
    }

    .to-map span {
      @extend %h5;
      color: #40A0D7;
    }
  }
}

.slide-y-transition {
  &-enter-active,
  &-leave-active {
    transition: 300ms cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  &-move {
    transition: transform 600ms;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(-15px);
  }
}
