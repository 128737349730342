@mixin media-query ($properties, $breakpoints: $grid-breakpoint) {
  @each $property, $values in $properties {
    @each $breakpoint, $value in $values {
      @media screen and (min-width: map_get($breakpoints, $breakpoint)) {
        #{$property}: $value;
      }
    }
  }
}


@mixin media-min-width($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}

@mixin media-max-width($size) {
  @media screen and (max-width: $size) {
    @content;
  }
}

@mixin min-breakpoint($breakpoint) {
  @include media-min-width(map_get($grid-breakpoint, $breakpoint)) {
    @content;
  }
}

@mixin min-breakpoint-landscape ($breakpoint) {
  @media screen and (min-height: map_get($grid-breakpoint, $breakpoint)) and (orientation: landscape) {
    @content;
  }
}


@mixin max-breakpoint($breakpoint) {
  $max-width: map_get($grid-breakpoint, $breakpoint) - .1px;

  @include media-max-width($max-width) {
    @content;
  }
}
